<template>
  <div class="header relative">
    <div class="inner flex jc-between ai-center">
      <div class="left flex ai-center cursor-pointer">
        <img class="logo" :src="logo" @click="handleGoHome()" />
        <div class="sysName" @click="handleGoHome()">{{ sysName }}</div>
        <!-- <img class="title" src="../../images/logo-name.png" @click="handleGoHome()" /> -->
        <div class="tabs flex ai-center">
          <div v-for="(item, index) in navList" :key="index" :class="[ 'tab relative cursor-pointer', { actived: item.pager == current }]" @click="handleTab(item)">{{ item.name }}</div>
        </div>
      </div>
      <div class="right flex ai-center cursor-pointer" @click="handleGoLogin()">
        <img class="photo" :src="avatar" />
        <div class="nickname">{{ loginFlag ? '退出登录' : '请登录' }}</div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { queryStudyDetail, MyInfo } from "@/api/ApiConfig";
export default {
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      sysName: '虚拟仿真实训平台',
      logo: require('../../images/logo.png'),
      current: localStorage.getItem('pager'),
      navList: [
        {
          name: '官网首页',
          pager: 'pager2Home'
        },
        {
          name: '实验教学团队',
          pager: 'pager2Team'
        },
        {
          name: '资料文件',
          pager: 'pager2Resource'
        },
        {
          name: '题库',
          pager: 'pager2Questions'
        },
        // {
        //   name: '系统设置',
        //   pager: 'pager2Setting'
        // },
      ],
      avatar: require('../../images/people-icon1.png'),
      loginFlag: localStorage.getItem("pcToken") || "",
    };
  },
  mounted() {
    this.getInfo()
    this.queryStudyDetail()
  },
  methods: {
    handleTab(item) {
      this.current = item.pager
      this.$emit('change', {
        pager: item.pager,
        params: {}
      })
    },
    changeCurrent(item) {
      this.current = item.pager
    },
    async queryStudyDetail() {
      const res = await queryStudyDetail({
        id: this.laboratory_id
      })
      if (res.code === 200) {
        if(res.data.logo) {
          this.logo = res.data.logo
        }
        this.sysName = res.data.sysName
      }
    },
    getInfo() {
      MyInfo().then(res => {
        if (res.code == 200) {
          this.avatar = (res.data && res.data.pic) ? res.data.pic : require('../../images/people-icon1.png');
        }
      });
    },
    handleGoHome() {
      this.$emit('change', {
        pager: 'pager2Home',
        params: {}
      })
    },
    handleGoLogin() {
      if (!this.loginFlag) {
        this.$message.error("请登录");
        setTimeout(() => {
          this.$router.replace("/login");
        }, 1500);
        return;
      } else {
        this.$confirm("此操作将退出登录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.$message({
              type: "success",
              message: "退出成功!"
            });
            localStorage.removeItem("pcToken");
            this.$router.replace("/login");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消退出"
            });
          });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  height: 90px;
  background: #fff;
  box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
  .inner {
    width: 70%;
    min-width: 1200px;
    height: 90px;
    margin: 0 auto;
    .logo {
      width: 100px;
      height: 48px;
    }
    .sysName {
      margin: 0 73px 0 13px;
      font-size: 26px;
    }
    .title {
      width: 208px;
      height: 27px;
      margin: 0 73px 0 13px;
    }
    .tabs {
      .tab {
        height: 28px;
        line-height: 28px;
        font-size: 16px;
        font-weight: bold;
        color: #555555;
        text-shadow: 0px 2px 14px rgba(45,77,130,0.22);
        margin: 0 20px;
        &.actived {
          transform-origin: center bottom;
          transform: scale(1.25);
          color: #327CED;
          &::before {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -4px;
            margin-left: -40px;
            width: 80px;
            height: 4px;
            background: #327CED;
            border-radius: 2px;
          }
        }
      }
    }
    .photo {
      width: 26px;
      height: 26px;
    }
    .nickname {
      height: 22px;
      line-height: 22px;
      font-size: 16px;
      font-weight: bold;
      color: #555555;
      margin-left: 10px;
    }
  }
}
</style>
  