<template>
  <div class="laboratory2">
    <LabHeader ref="LabHeader" :pager="pager" :params="params" @change="changePager" />
    <Pager3Home v-if="pager == 'pager3Home'" :pager="pager" :params="params" @change="changePager" />
    <Pager3Team v-else-if="pager == 'pager3Team'" :pager="pager" :params="params" @change="changePager" />
    <Pager3Resource v-else-if="pager == 'pager3Resource'" :pager="pager" :params="params" @change="changePager" />
    <Pager3Questions v-else-if="pager == 'pager3Questions'" :pager="pager" :params="params" @change="changePager" />
    <Pager3Setting v-else-if="pager == 'pager3Setting'" :pager="pager" :params="params" @change="changePager" />
    <Pager3Report v-else-if="pager == 'pager3Report'" :pager="pager" :params="params" @change="changePager" />
    <Pager3Third v-else-if="pager == 'pager3Third'" :pager="pager" :params="params" @change="changePager" />
    <LabFooter />
  </div>
</template>
<script>
import LabHeader from "./components/header";
import LabFooter from "./components/footer";
import Pager3Home from "./home";
import Pager3Team from "./team";
import Pager3Resource from "./resource";
import Pager3Questions from "./questions";
import Pager3Setting from "./setting";
import Pager3Report from "./report";
import Pager3Third from "./third";

export default {
  components: {
    LabHeader, 
    LabFooter,
    Pager3Home,
    Pager3Team,
    Pager3Resource, 
    Pager3Questions, 
    Pager3Setting,
    Pager3Report,
    Pager3Third
  },
  data() {
    return {
      pager: localStorage.getItem('pager') || 'pager3Home',
      params: JSON.parse(localStorage.getItem('pager_params')) || {}
    };
  },
  computed: {},
  mounted() {},
  methods: {
    changePager({pager, params}) {
      localStorage.setItem('pager', pager)
      localStorage.setItem('pager_params', JSON.stringify(params))
      this.pager = pager
      this.params = params
      this.$refs.LabHeader.changeCurrent({
        pager
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.laboratory2 {
  background-color: #F4F5F8;
}
</style>
