<template>
  <div class="chart-pager">
    <div class="detail">
      <div class="inner">
        <div class="name">总量趋势</div>
        <div class="chart" id="chart1"></div>
        <div class="chart" id="chart2"></div>
        <div class="chart" id="chart3"></div>
      </div>
    </div>
  </div>
</template>
<script>
import _object from 'lodash/object'
import * as echarts from "echarts";
import { studyChart } from "@/api/ApiConfig.js";

export default {
  props: ['pager', 'params'],
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      chart_data: {
        data1: [],
        data2: [],
        data3: []
      },
      myChart: {
        echart1: null,
        echart2: null,
        echart3: null
      },
      default_opt: {
        grid: {
          top: 60,
          left: 50,
          right: 50,
          bottom: 50
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: 10
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: []
      }
    };
  },
  computed: {
    chartOpt1() {
      return _object.merge(
        JSON.parse(JSON.stringify(this.default_opt)),
        {
          color: ['#327CED'],
          legend: {
            data: ['访问总量'],
            textStyle: {
              color: '#327CED',
              fontWeight: 'bold'
            }
          },
          xAxis: {
            data: this.chart_data.data1.map(item => item.time)
          },
          series: [
            {
              type: 'line',
              name: '访问总量',
              smooth: true,
              lineStyle: {
                shadowColor: '#327CED',
                shadowBlur: 10,
                shadowOffsetY: 5
              },
              data: this.chart_data.data1.map(item => parseFloat(item.viewCount))
            }
          ]
        }
      )
    },
    chartOpt2() {
      return _object.merge(
        JSON.parse(JSON.stringify(this.default_opt)),
        {
          color: ['#FF5D26'],
          legend: {
            data: ['练习总次数'],
            textStyle: {
              color: '#FF5D26',
              fontWeight: 'bold'
            }
          },
          xAxis: {
            data: this.chart_data.data2.map(item => item.time)
          },
          series: [
            {
              type: 'line',
              name: '练习总次数',
              smooth: true,
              lineStyle: {
                shadowColor: '#FF5D26',
                shadowBlur: 10,
                shadowOffsetY: 5
              },
              data: this.chart_data.data2.map(item => parseFloat(item.studyCount))
            }
          ]
        }
      )
    },
    chartOpt3() {
      return _object.merge(
        JSON.parse(JSON.stringify(this.default_opt)),
        {
          color: ['#2CB457'],
          legend: {
            data: ['实验结果总数'],
            textStyle: {
              color: '#2CB457',
              fontWeight: 'bold'
            }
          },
          xAxis: {
            data: this.chart_data.data3.map(item => item.time)
          },
          series: [
            {
              type: 'line',
              name: '实验结果总数',
              smooth: true,
              lineStyle: {
                shadowColor: '#2CB457',
                shadowBlur: 10,
                shadowOffsetY: 5
              },
              data: this.chart_data.data3.map(item => parseFloat(item.allUserCount))
            }
          ]
        }
      )
    }
  },
  async mounted() {
    await this.studyChart()
    this.initChart()
  },
  methods: {
    changePager({ pager, params }) {
      this.$emit('change', { pager, params })
    },
    async studyChart() {
      const res = await studyChart({
        channel: '实验',
        studyId: this.laboratory_id
      })
      if (res.code === 200) {
        res.data = res.data.map(item => {
          item.studyCount = Number(item.studyCount)
          item.viewCount = Number(item.viewCount)
          item.allUserCount = Number(item.allUserCount)
          return item
        })
        this.chart_data.data1 = res.data.reverse()
        this.chart_data.data2 = res.data.reverse()
        this.chart_data.data3 = res.data.reverse()
      }
    },
    initChart() {
      this.myChart.echart1 = echarts.init(document.getElementById("chart1"));
      this.myChart.echart2 = echarts.init(document.getElementById("chart2"));
      this.myChart.echart3 = echarts.init(document.getElementById("chart3"));
      this.myChart.echart1.setOption(this.chartOpt1);
      this.myChart.echart2.setOption(this.chartOpt2);
      this.myChart.echart3.setOption(this.chartOpt3);
      window.addEventListener('resize', () => {
        this.myChart.echart1.resize();
        this.myChart.echart2.resize();
        this.myChart.echart3.resize();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.chart-pager {
  .detail {
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    .inner {
      opacity: 0.86;
      .name {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
      }
      .chart {
        height: 460px;
      }
    }
  }
}
</style>
