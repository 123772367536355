<template>
  <div class="home-pager">
    <div class="homeTop">
      <div class="inner">
        <div class="lab-info flex jc-between ai-normal">
          <div class="left flex-1 flex flex-column jc-between ai-normal">
            <video
              class="video"
              ref="videoRef"
              width="900px"
              disablePictureInPicture
              controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
              controls
              autoplay
              :src="video_link"
            />
            <div class="title">{{ title }}</div>
            <div class="video-btns flex jc-between ai-center">
              <div class="flex ai-center">
                <el-button v-if="video" :class="['video-btn', { 'actived': videoActived == 0 }]" type="primary" @click="handleChangeVideo(video, 0)">实验简介视频</el-button>
                <el-button v-if="videoBlurb" :class="['video-btn', { 'actived': videoActived == 1 }]" type="primary" @click="handleChangeVideo(videoBlurb, 1)">实验引导视频</el-button>
                <div class="flex ai-center">
                  <span class="rate">{{ average }}分</span>
                  <el-rate v-model="average" disabled :colors="['#F0A256', '#F0A256', '#F0A256']" disabled-void-color="#A8CFFF"></el-rate>
                </div>
              </div>
              <div class="flex ai-center">
                <div class="opt-btn cursor-pointer flex ai-center" size="small" type="warning" @click="handleCollect()"><img class="icon" :src="isCollect ? require('../images/pager2-collect-actived.png') : require('../images/pager2-collect.png')" />收藏</div>
                <div class="opt-btn cursor-pointer flex ai-center" size="small" type="warning" @click="handlePraise()"><img class="icon" :src="isLike ? require('../images/pager2-praise-actived.png') : require('../images/pager2-praise.png')" />点赞</div>
              </div>
            </div>
          </div>
          <div class="right relative flex flex-column jc-between ai-normal">
            <div class="flex-1 flex flex-column ai-normal">
              <div class="info">
                <div class="label flex ai-center">软件信息<img class="icon" src="../images/tit-line.png" /></div>
                <div class="tit">所属实验：{{ title }}</div>
                <div class="from">讲师：{{ teacher }}</div>
                <div class="desc">{{ descs }}</div>
              </div>
              <el-select class="phase" v-model="studyId" placeholder="请选择" @change="handleChangeStudyId()">
                <el-option
                  v-for="item in studyIdList"
                  :key="'studyId_' + item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="btns flex ai-center">
              <el-button class="btn" type="primary" @click="handleParticipation()">{{ userType == 0 ? '参与实验' : '授课实验'}}</el-button>
              <el-button v-if="userType == 0" class="btn" type="primary" @click="handleExam()">我要考试</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navigate">
      <div class="inner">
        <div class="tabs1 flex jc-between ai-center">
          <div v-for="(item, index) in navigate" :key="'navigate_' + index" :class="['tab1 flex flex-column jc-center ai-center cursor-pointer', { actived: navigateActived === index }]" @click="handleTab(1, index, item)">
            <img class="img" :src="item.icon" />
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
        <Score v-if="navigateActived === 8" />
        <Record v-if="navigateActived === 1" />
        <Team v-if="navigateActived === 3" />
        <Files v-if="navigateActived === 4" />
        <Chart v-if="navigateActived === 6" />
        <div v-if="navigateActived === 0" class="tabs2 flex ai-center">
          <div v-for="(item, index) in navigate2" :key="'navigate2_' + index" :class="['tab2 cursor-pointer', { actived: navigateChildActived === index }]" @click="handleTab(2, index, item)">{{ item.name }}</div>
        </div>
        <div v-if="(navigateActived === 0 && navigate2[navigateChildActived].type) || (navigateActived !== 0 && navigate[navigateActived].type)" class="detail">
          <div v-html="navigateActived === 0 ? detail[navigate2[navigateChildActived].type] : detail[navigate[navigateActived].type]"></div>
        </div>
        <div v-else-if="navigate[navigateActived].children && navigate[navigateActived].children.length > 0" class="detail">
          <div v-for="(item, index) in navigate[navigateActived].children" :key="'navigate_child_' + index" class="it">
            <div class="title">{{ item.title }}</div>
            <div v-html="detail[item.type]"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="chart">
      <div class="first-title">实验项目情况</div>
      <div class="bar-chart" id="bar-chart"></div>
    </div>
    <div class="project">
      <div class="inner flex ai-center">
        <div class="browser flex flex-column ai-normal">
          <div class="first-title">实验系统浏览器要求</div>
          <div class="flex jc-between ai-center">
            <div v-for="(item, index) in browser" :key="'browser_' + index" class="browser-item flex flex-column jc-between ai-center">
              <div class="name">{{ item.name }}</div>
              <img class="img" :src="item.icon" />
            </div>
          </div>
        </div>
        <div class="team flex flex-column ai-normal">
          <div class="first-title">团队介绍</div>
          <div class="flex-1 team-box flex flex-column">
            <div class="second-title flex jc-between ai-center">
              <div class="tit">团队成员</div>
              <div class="more flex jc-center ai-center cursor-pointer" @click="goTeam()">
                <div class="txt">更多 &gt;</div>
              </div>
            </div>
            <img class="team-line" src="../images/team-line.png" />
            <div class="flex ai-center">
              <div class="team-item flex ai-center">
                <div class="name head">负责人 {{ teamHead.name }}</div>
              </div>
            </div>
            <div class="flex-1 flex flex-wrap ai-center overflow-auto">
              <div v-for="(item, index) in teamUser" :key="index" class="team-item flex ai-center">
                <div class="name">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="recommend">
          <div class="flex jc-between ai-center">
            <div class="name">{{ recommend.name }}</div>
            <div class="people flex ai-center">
              <img class="icon" src="../images/people-icon2.png" />
              <div class="num">{{ recommend.viewNum }}人学习</div>
            </div>
          </div>
          <div class="line"></div>
          <div class="auther">{{ recommend.teacher }}</div>
          <div class="desc" v-html="recommend.content.replace(/<[^>]+>/g, '')"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import Score from "./components/score";
import Record from "./components/record";
import Team from "./components/team";
import Files from "./components/files";
import Chart from "./components/chart";
import { MyInfo, studyModuleList, queryStudyDetail, studyJoinNum, comprehensiveDivide, showRecord, classRecommend, collectItem, studyLike, partakeStudy } from "@/api/ApiConfig";

export default {
  components: { Score, Record, Team, Files, Chart },
  props: ['pager', 'params'],
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      Token: localStorage.getItem('pcToken') || '',
      videoActived: 0,
      userType: '0',
      studyIdList: [],
      studyId: null,
      myChart: null,
      chartData: [],
      title: '',
      average: 0,
      teacher: '',
      descs: '',
      isCollect: false,
      isLike: false,
      video: '',
      videoBlurb: '',
      video_link: '',
      studyLink: '',
      testLink: '',
      hardwareAsk: '',
      interAsk: '',
      systemAsk: '',
      detail: {
        teachingTarget: '',
        studyPrinciple: '',
        processMethod: '',
        studyStep: '',
        result: '',
        conclusion: '',
        ask: '',
        characteristic: ''
      },
      navigateActived: 0,
      navigateChildActived: 0,
      navigate: [
        {
          icon: require('../images/pager2-quick-icon1.png'),
          name: '实验介绍',
          type: ''
        },
        {
          icon: require('../images/pager2-quick-icon2.png'),
          name: '参与实验记录',
          type: ''
        },
        {
          icon: require('../images/pager2-quick-icon3.png'),
          name: '实验必读',
          type: '',
          children: [
            {
              title: '硬件要求',
              type: 'hardwareAsk'
            },
            {
              title: '网络要求',
              type: 'interAsk'
            },
            {
              title: '用户操作系统要求',
              type: 'systemAsk'
            }
          ]
        },
        {
          icon: require('../images/pager2-quick-icon4.png'),
          name: '教学团队',
          type: ''
        },
        {
          icon: require('../images/pager2-quick-icon5.png'),
          name: '实验文件',
          type: ''
        },
        {
          icon: require('../images/pager2-quick-icon6.png'),
          name: '实验报告',
          type: '',
          pager: 'pager2Report',
          params: {}
        },
        {
          icon: require('../images/pager2-quick-icon7.png'),
          name: '数据统计',
          type: ''
        },
        {
          icon: require('../images/pager2-quick-icon8.png'),
          name: '考核要求',
          type: 'ask'
        },
        {
          icon: require('../images/pager2-quick-icon9.png'),
          name: '实验评分',
          type: ''
        },
        {
          icon: require('../images/pager2-quick-icon10.png'),
          name: '实验教学项目的特色',
          type: 'characteristic'
        }
      ],
      navigate2: [
        {
          name: '实验教学目标',
          type: 'teachingTarget'
        },
        {
          name: '实验原理',
          type: 'studyPrinciple'
        },
        {
          name: '实验教学过程与实验方法',
          type: 'processMethod'
        },
        {
          name: '实验步骤',
          type: 'studyStep'
        },
        {
          name: '实验结果与结论',
          type: 'conclusion',
        }
      ],
      teamHead: {
        photo: '',
        name: '',
        desc: ''
      },
      teamUser: [],
      browser: [
        {
          name: '谷歌浏览器',
          icon: require('../images/chrome.png')
        },
        {
          name: '火狐浏览器',
          icon: require('../images/firefox.png')
        },
        {
          name: 'QQ浏览器',
          icon: require('../images/qq.png')
        }
      ],
      recommend: {
        name: "--",
        teacher: '--',
        viewNum: 0,
        content: '--'
      }
    };
  },
  computed: {
    chartOpt() {
      return {
        grid: {
          top: 60,
        },
        color: [
          {
            type: 'linear',
            x: 0, y: 0, x2: 0, y2: 1,
            colorStops: [{ offset: 0, color: 'rgba(50, 124, 237, 1)' }, { offset: 1, color: 'rgba(50, 124, 237, 0.2)' }],
            global: false
          },
          {
            type: 'linear',
            x: 0, y: 0, x2: 0, y2: 1,
            colorStops: [{ offset: 0, color: 'rgba(255, 117, 70, 1)' }, { offset: 1, color: 'rgba(255, 117, 70, 0.2)' }],
            global: false
          }
        ],
        legend: {
          data: ['实验项目使用量', '实验项目浏览量'],
          top: 10
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: this.chartData.map(item => item.time)
        },
        yAxis: {
          name: '人次',
          nameTextStyle: {
            padding: [0, 0, 30, 0]
          },
          type: 'value',
          minInterval: 1,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            name: '实验项目使用量',
            barWidth: 24,
            itemStyle: {
              barBorderRadius: 12
            },
            data: this.chartData.map(item => parseFloat(item.studyUseNum))
          },
          {
            type: 'bar',
            name: '实验项目浏览量',
            barWidth: 24,
            itemStyle: {
              barBorderRadius: 12
            },
            data: this.chartData.map(item => parseFloat(item.studyViewNum))
          }
        ]
      }
    }
  },
  async mounted() {
    await this.getInfo()
    this.init()
  },
  methods: {
    async getInfo() {
      const res = await MyInfo()
      if (res.code == 200) {
        this.userType = res.data.userType
      }
    },
    handleTab(idx, index, item) {
      if (idx === 1) {
        if(item.pager) {
          if(item.pager === 'pager2Report' && this.userType == 1) {
            this.navigateActived = 1
          } else {
            this.$emit('change', { pager: item.pager, params: item.params })
          }
        } else {
          this.navigateActived = index
        }
      } else {
        this.navigateChildActived = index
      }
    },
    changePager({ pager, params }) {
      this.$emit('change', { pager, params })
    },
    async handleChangeStudyId() {
      localStorage.setItem('laboratory_id', this.studyId)
      this.laboratory_id = this.studyId
      this.$emit('change', { pager: 'pager2Home', params: {} })
      await this.init()
      this.studyId = null
    },
    async init() {
      await this.queryStudyDetail()
      await this.comprehensiveDivide()
      await this.studyJoinNum()
      this.initChart()
      // this.showRecord()
      this.classRecommend()
    },
    classRecommend() {
      classRecommend({
        channel: '实验'
      }).then(res => {
        if (res.code === 200) {
          this.recommend = {
            name: res.data.length > 0 ? res.data[0].name : "--",
            teacher: res.data.length > 0 ? res.data[0].teacher : "--",
            viewNum: res.data.length > 0 ? res.data[0].viewNum || 0 : 0,
            content: res.data.length > 0 ? res.data[0].content : "--"
          };
        }
      });
    },
    async studyJoinNum() {
      const res = await studyJoinNum({
        channel: '实验',
        id: this.laboratory_id
      })
      if (res.code === 200) {
        this.chartData = res.data.reverse()
      }
    },
    async queryStudyDetail() {
      const res = await queryStudyDetail({
        id: this.laboratory_id
      })
      const res_list = await studyModuleList({
        channel: "实验",
        listType: '',
        type: '',
        type2: '',
        pageIndex: 1,
        pageSize: 999999
      })
      if (res_list.code === 200) {
        this.studyIdList = res_list.data.list.filter(item => res.data.studyIdList.split(',').includes(item.id)).map(item => ({
          id: item.id,
          name: item.name
        }))
      }
      if (res.code === 200) {
        console.log(1112, res.data.studyLink)
        this.title = res.data.name
        this.teacher = res.data.teacher
        this.descs = res.data.descs || ''
        this.isCollect = res.data.isCollect
        this.isLike = res.data.isLike
        this.video = res.data.video
        this.videoBlurb = res.data.videoBlurb
        this.video_link = this.video
        this.studyLink = res.data.studyLink
        this.testLink = res.data.testLink
        this.teamHead = JSON.parse(res.data.teamHead)
        this.teamUser = JSON.parse(res.data.teamUser)
        this.detail.teachingTarget = res.data.teachingTarget
        this.detail.studyPrinciple = res.data.studyPrinciple
        this.detail.processMethod = res.data.processMethod
        this.detail.studyStep = res.data.studyStep
        this.detail.result = res.data.result
        this.detail.conclusion = res.data.conclusion
        this.detail.ask = res.data.ask
        this.detail.characteristic = res.data.characteristic
        this.detail.hardwareAsk = res.data.hardwareAsk
        this.detail.interAsk = res.data.interAsk
        this.detail.systemAsk = res.data.systemAsk
      }
    },
    async comprehensiveDivide() {
      const res = await comprehensiveDivide({
        id: this.laboratory_id
      })
      if (res.code === 200) {
        this.average =  Number(
          ((Number(res.data.content) + Number(res.data.feature) + Number(res.data.passingRate)) / 3).toFixed(1)
        )
      }
    },
    showRecord() {
      showRecord({
        channel: '实验',
        id: this.laboratory_id
      })
    },
    initChart() {
      this.myChart = echarts.init(document.getElementById("bar-chart"));
      this.myChart.setOption(this.chartOpt);
      window.addEventListener('resize', () => {
        this.myChart.resize();
      });
    },
    handleChangeVideo(video_link, videoActived) {
      this.videoActived = videoActived
      this.video_link = video_link
    },
    goTeam() {
      this.$emit('change', { pager: "pager2Team", params: {} })
    },
    async handleParticipation() {
      if (this.studyLink.includes("http")) {
        await partakeStudy({
          studyId: this.laboratory_id
        })
        window.open(`${this.studyLink}?user_id=${this.Token}&study_id=${this.laboratory_id}`, "_blank");
      } else {
        const res = await partakeStudy({
          studyId: this.laboratory_id
        })
        this.$emit('change', { pager: "pager2Third", params: {
          doc: JSON.parse(res.data).content
        }})
        // this.$message.warning('实验系统正在开发中...')
      }
    }, 
    handleExam() {
      let loginFlag = localStorage.getItem("pcToken") || "";
      if (!loginFlag) {
        this.$message.error("请登录");
        setTimeout(() => {
          this.$router.replace("/login");
        }, 1500);
        return;
      }
      if (this.testLink.includes("http")) {
        window.open(this.testLink, "_blank");
      } else {
        this.$message.warning('考试系统正在开发中...')
      }
    },
    handleCollect() {
      collectItem({
        channel: "实验",
        id: this.laboratory_id
      }).then(async res => {
        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        await this.queryStudyDetail();
        if (this.isCollect) {
          this.$message.success("收藏成功");
        } else {
          this.$message.success("已取消收藏");
        }
      });
    },
    handlePraise() {
      studyLike({
        channel: "实验",
        id: this.laboratory_id
      }).then(async res => {
        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        await this.queryStudyDetail();
        if (this.isLike) {
          this.$message.success("点赞成功");
        } else {
          this.$message.success("已取消点赞");
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.home-pager {
  .first-title {
    font-size: 26px;
    margin: 0 0 30px 15px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -4px;
      width: 22px;
      height: 4px;
      background: #327CED;
      border-radius: 2px;
    }
  }
  .second-title {
    margin-top: 6px;
  }
  .homeTop {
    margin: 0 auto;
    background: url('../images/lab-bg1.png') no-repeat center center;
    background-size: 100% 100%;
    .inner {
      width: 1200px;
      height: 600px;
      overflow: hidden;
      margin: 0 auto;
      padding: 50px 0;
    }
    .lab-info {
      .left {
        .video {
          width: 662px;
          height: 372px;
          border-radius: 20px;
        }
        .title {
          font-size: 26px;
          font-weight: bold;
          margin: 20px 0;
        }
        .video-btns {
          .rate {
            margin: 0 20px;
          }
          .video-btn {
            background: none;
            margin: 0 7px;
            border: solid 1px #327CED;
            color: #327CED;
            &.actived {
              background: #327CED;
              color: #fff;
            }
            .icon {
              width: 32px;
              height: 32px;
              margin: -11px -11px -11px 0;
            }
          }
          .opt-btn {
            margin: 0 10px;
            font-size: 12px;
            color: #666666;
            .icon {
              width: 20px;
              height: 20px;
              margin-right: 4px;
            }
          }
          .btns {
            .btn {
              background: #327CED;
            }
            .red-btn {
              background: #FF5D26;
            }
          }
        }
      }
      .right {
        margin-left: 30px;
        .label {
          font-size: 20px;
          font-weight: bold;
          color: #327CED;
          line-height: 28px;
          .icon {
            width: 25px;
            height: 18px;
            margin-left: 5px;
          }
        }
        .tit {
          font-size: 20px;
          font-weight: bold;
          color: #333;
          line-height: 25px;
          margin: 10px 0;
        }
        .from {
          font-size: 18px;
          color: #333333;
          line-height: 25px;
          margin: 10px 0;
        }
        .desc {
          font-size: 18px;
          color: #666666;
          line-height: 1.5;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          line-clamp: 5;
          overflow: hidden;
          text-indent: 2em;
        }
        .phase {
          margin: 20px 0;
          width: 100%;
        }
        .btns {
          .btn {
            background: #327CED;
            .icon {
              width: 32px;
              height: 32px;
              margin: -11px -11px -11px 0;
            }
          }
          .red-btn {
            background: #FF5D26;
          }
        }
      }
    }
  }
  .navigate {
    background: #fff;
    .inner {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 40px;
      .tabs1 {
        border-bottom: solid 1px #D6E3FD;
        .tab1 {
          border-bottom: solid 4px transparent;
          margin-bottom: -4px;
          padding: 20px 10px;
          .img {
            height: 60px;
            width: auto;
          }
          .name {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            line-height: 25px;
          }
          &.actived {
            border-bottom-color: #467AE5;
          }
        }
      }
      .tabs2 {
        height: 62px;
        .tab2 {
          padding: 0 50px 0 10px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          line-height: 62px;
          &.actived {
            color: #327CED;
          }
        }
      }
      .detail {
        font-size: 14px;
        color: #666666;
        line-height: 28px;
        padding: 10px 30px 0;
        .title {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          line-height: 36px;
          margin: 15px 0;
        }
        /deep/ img {
          max-width: 100%;
        }
      }
    }
  }
  .chart {
    width: 100%;
    margin: 50px auto;
    .first-title {
      max-width: 1200px;
      margin: 0 auto;
    }
    .bar-chart {
      width: 100%;
      max-width: 1200px;
      height: 400px;
      padding-top: 20px;
      margin: 0 auto;
    }
  }
  .project {
    width: 100%;
    max-width: 1200px;
    margin: 25px auto 50px;
    .inner {
      width: 100%;
      max-width: 1240px;
      .browser {
        .browser-item {
          width: 206px;
          height: 258px;
          margin: 0 15px;
          background: #fff;
          border-radius: 10px;
          padding: 30px 0 7px;
          box-sizing: border-box;
          .name {
            height: 25px;
            line-height: 25px;
            font-size: 18px;
            font-weight: bold;
            color: #333333;
          }
          .img {
            width: 114px;
            height: 114px;
          }
        }
      }
      .team {
        height: 329px;
        .second-title {
          .tit {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            line-height: 25px;
          }
        }
        .more {
          .txt {
            font-size: 16px;
            color: #327CED;
            line-height: 22px;
          }
        }
        .team-line {
          height: 2px;
          width: 100%;
          margin: 18px 0 20px;
        }
        .team-box {
          width: 490px;
          height: 258px;
          border-radius: 10px;
          background: #fff;
          padding: 30px;
          margin-left: 15px;
          box-sizing: border-box;
        }
        .team-item {
          width: 33.33%;
          margin: 9px 0;
          .name {
            font-size: 14px;
            color: #666666;
            line-height: 20px;
          }
          .head {
            color: #327CED;
          }
        }
      }
    }
  }
}
</style>
