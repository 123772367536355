<template>
  <div class="record-pager">
    <div class="detail">
      <div class="inner">
        <div class="name">学习记录</div>
        <el-table :data="records" style="width: 100%">
          <el-table-column prop="userName" align="center" label="姓名" min-width="120"></el-table-column>
          <el-table-column prop="studyName" align="center" label="实验名称" min-width="150"></el-table-column>
          <el-table-column prop="studyResult" align="center" label="实验结果" min-width="150"></el-table-column>
          <el-table-column prop="startTime" align="center" label="实验开始时间" min-width="160"></el-table-column>
          <el-table-column prop="endTime" align="center" label="实验结束时间" min-width="160"></el-table-column>
          <el-table-column prop="useTime" align="center" label="实验用时" min-width="120"></el-table-column>
          <el-table-column prop="score" align="center" label="实验成绩" min-width="120"></el-table-column>
          <el-table-column prop="reportWorld" align="center" label="实验报告" min-width="240">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row, 1)">文本报告</el-button>
              <el-button v-if="scope.row.reportWorld" type="text" @click="handleDetail(scope.row, 2)">文档报告</el-button>
              <el-button v-if="scope.row.reportOther" type="text" @click="handleDetail(scope.row, 0)">三方报告</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            align="center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 40, 50, 100]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { partakeStudyLog } from "@/api/ApiConfig.js";

export default {
  props: ['pager', 'params'],
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      page: 1,
      size: 10,
      total: 0,
      records: []
    };
  },
  computed: {
  },
  mounted() {
    this.getData()
  },
  methods: {
    changePager({ pager, params }) {
      this.$emit('change', { pager, params })
    },
    getData() {
      partakeStudyLog({
        studyId: this.laboratory_id,
        pageIndex: this.page,
        pageSize: this.size
      }).then(res => {
        if (res.code === 200) {
          this.records = res.data.list || [];
          this.total = res.data.total
        }
      });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    handleDetail(row, type) {
      if(type == 0) {
        // 查看三方报告 row.reportOther
        this.previewImages = row.reportOther.split(',')
        this.$msgbox({
          title: '预览',
          message: this.$createElement('div', {
            style: {
              width: '388px',
              maxHeight: '400px',
              overflow: 'auto'
            }
          }, this.previewImages.map(item => this.$createElement('img', { attrs: { src: item }, style: { width: '100%', height: 'auto' } }, []))),
          showCancelButton: false,
          confirmButtonText: '关闭'
        })
      } else if(type == 1) {
        this.$emit('change', { pager: 'pager3Report', params: {
          data: row
        }})
      } else if(type == 2) {
        if(row.reportWorld.slice(-3) == 'pdf') {
          window.open(row.reportWorld)
        } else {
          window.open('https://view.officeapps.live.com/op/view.aspx?src=' + row.reportWorld)
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.record-pager {
  .detail {
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    .inner {
      font-size: 14px;
      color: #666666;
      line-height: 2;
      opacity: 0.86;
      .name {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
        margin-bottom: 10px;
      }
      .pagination {
        margin-top: 20px;
      }
    }
  }
}
</style>
