<template>
  <div class="laboratory1">
    <LabHeader ref="LabHeader" :pager="pager" :params="params" @change="changePager" />
    <Pager1Home v-if="pager == 'pager1Home'" :pager="pager" :params="params" @change="changePager" />
    <Pager1Chart v-else-if="pager == 'pager1Chart'" :pager="pager" :params="params" @change="changePager" />
    <Pager1Detail v-else-if="pager == 'pager1Detail'" :pager="pager" :params="params" @change="changePager" />
    <Pager1Files v-else-if="pager == 'pager1Files'" :pager="pager" :params="params" @change="changePager" />
    <Pager1Read v-else-if="pager == 'pager1Read'" :pager="pager" :params="params" @change="changePager" />
    <Pager1Record v-else-if="pager == 'pager1Record'" :pager="pager" :params="params" @change="changePager" />
    <Pager1Score v-else-if="pager == 'pager1Score'" :pager="pager" :params="params" @change="changePager" />
    <Pager1Team v-else-if="pager == 'pager1Team'" :pager="pager" :params="params" @change="changePager" />
    <Pager1Resource v-else-if="pager == 'pager1Resource'" :pager="pager" :params="params" @change="changePager" />
    <Pager1Questions v-else-if="pager == 'pager1Questions'" :pager="pager" :params="params" @change="changePager" />
    <Pager1Setting v-else-if="pager == 'pager1Setting'" :pager="pager" :params="params" @change="changePager" />
    <Pager1Report v-else-if="pager == 'pager1Report'" :pager="pager" :params="params" @change="changePager" />
    <Pager1Third v-else-if="pager == 'pager1Third'" :pager="pager" :params="params" @change="changePager" />
    <LabFooter :pager="pager" :params="params" @change="changePager" />
  </div>
</template>
<script>
import LabHeader from "./components/header";
import LabFooter from "./components/footer";
import Pager1Home from "./home";
import Pager1Chart from "./chart";
import Pager1Detail from "./detail";
import Pager1Files from "./files";
import Pager1Read from "./read";
import Pager1Record from "./record";
import Pager1Score from "./score";
import Pager1Team from "./team";
import Pager1Resource from "./resource";
import Pager1Questions from "./questions";
import Pager1Setting from "./setting";
import Pager1Report from "./report";
import Pager1Third from "./third";

export default {
  components: {
    LabHeader, 
    LabFooter, 
    Pager1Home, Pager1Chart, Pager1Detail, Pager1Files, Pager1Read, Pager1Record, Pager1Score, Pager1Team, Pager1Resource, Pager1Questions, Pager1Setting, Pager1Report, Pager1Third
  },
  data() {
    return {
      pager: localStorage.getItem('pager') || 'pager1Home',
      params: JSON.parse(localStorage.getItem('pager_params')) || {}
    };
  },
  computed: {},
  mounted() {},
  methods: {
    changePager({pager, params}) {
      localStorage.setItem('pager', pager)
      localStorage.setItem('pager_params', JSON.stringify(params))
      this.pager = pager
      this.params = params
      this.$refs.LabHeader.changeCurrent({
        pager
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.laboratory1 {
  background-color: #F4F5F8;
  background: url('../images/lab-bg2.png') no-repeat left 42px top 1020px / 269px 267px, url('../images/lab-bg3.png') no-repeat right 42px top 1748px / 329px 327px;
}
</style>
