<template>
  <div class="files-pager">
    <!-- <Banner /> -->
    <Navigate :pager="pager" :params="params" @change="changePager" />
    <div class="detail filesPager">
      <div class="title text-align-center">实验文件</div>
      <div class="sub-title text-align-center">实验详情报告</div>
      <div class="inner flex flex-wrap">
        <div v-for="(item, index) in videoList" :key="index" class="item flex flex-column jc-between ai-center">
          <img v-if="item.videoAddr.split('.')[item.videoAddr.split('.').length - 1] == 'pdf'" class="icon" src="../images/pdf.png" />
          <img v-else-if="['docx', 'doc'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../images/doc.png" />
          <img v-else-if="['txt'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../images/txt.png" />
          <img v-else-if="['mp4', 'avi', 'wmv', 'mpeg', 'mp4', 'm4v', 'mov', 'asf', 'flv', 'f4v', 'rmvb', 'rm', '3gp', 'vob'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../images/video.png" />
          <img v-else-if="['ppt', 'pptx', 'pps', 'ppsx'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../images/ppt.png" />
          <img v-else-if="['bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'WMF', 'webp', 'avif', 'apng'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../images/image.png" />
          <img v-else-if="['html'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../images/article.png" />
          <img v-else class="chapter_icon" src="../images/file.png" />
          <div class="name ellipsis">{{ item.videoAddr ? item.videoAddr.split("/")[item.videoAddr.split("/").length - 1] : "" }}</div>
          <div class="btns">
            <el-button class="btn" size="small" @click="handlePreview(item)">预览</el-button>
            <el-button class="btn" size="small" @click="handleDownload(item)">下载</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Banner from "./components/banner";
import Navigate from "./components/navigate";
import { queryStudyDetail } from "@/api/ApiConfig.js";

export default {
  components: { Navigate },
  props: ['pager', 'params'],
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      videoList: [],
      dowland: '0'
    };
  },
  watch: {
    pager() {
      this.queryStudyDetail()
    },
    params() {
      this.queryStudyDetail()
    }
  },
  mounted() {
    this.queryStudyDetail()
  },
  methods: {
    changePager({ pager, params }) {
      this.$emit('change', { pager, params })
    },
    async queryStudyDetail() {
      const res = await queryStudyDetail({
        id: this.laboratory_id
      })
      if (res.code === 200) {
        this.videoList = res.data.videoList
        this.dowland = res.data.dowland
      }
    },
    handlePreview(item) {
      if(this.dowland === '1') {
        if(['bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'WMF', 'webp', 'avif', 'apng'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])) {
          this.$alert(`<img src=${item.videoAddr} style='position: fixed; left: 50%; top: 50%; margin-top: 0px; z-index: 9999; transform: translate(-50%, -50%); max-width: 80%; width: 80%; max-height: 80%; object-fit: contain;' />`, '', {
            dangerouslyUseHTMLString: true,
            showClose: true,
            closeOnClickModal: true,
            showConfirmButton: false,
            center: true,
            customClass: 'files-dialog'
          });
        } else if(['mp4', 'avi', 'wmv', 'mpeg', 'mp4', 'm4v', 'mov', 'asf', 'flv', 'f4v', 'rmvb', 'rm', '3gp', 'vob'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])) {
          this.$alert(`<video src=${item.videoAddr} style='position: fixed; left: 50%; top: 50%; margin-top: 0px; z-index: 9999; transform: translate(-50%, -50%); max-width: 80%; width: 80%; max-height: 80%; object-fit: contain;' autoplay controls />`, '', {
            dangerouslyUseHTMLString: true,
            showClose: true,
            closeOnClickModal: true,
            showConfirmButton: false,
            center: true,
            customClass: 'files-dialog'
          });
        } else {
          window.open(item.videoAddr)
        }
      } else {
        this.$alert('文件不允许查看', '提示', { confirmButtonText: '确定' });
        // this.$message.error('文件不允许查看')
      }
    },
    handleDownload(item) {
      if(this.dowland === '1') {
        window.open(item.videoAddr)
      } else {
        this.$alert('文件不允许查看', '提示', { confirmButtonText: '确定' });
        // this.$message.error('文件不允许查看')
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.files-pager {
  background-color: #F4F5F8;
  .detail {
    width: 100%;
    max-width: 1200px;
    margin: 25px auto 50px;
    box-sizing: border-box;
    .title {
      height: 42px;
      line-height: 42px;
      font-size: 30px;
      font-weight: bold;
      color: #333333;
    }
    .sub-title {
      height: 25px;
      line-height: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #234984;
      margin-top: 6px;
    }
    .inner {
      width: 1156px;
      padding: 40px 60px;
      background: #FFFFFF;
      box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
      border-radius: 20px;
      opacity: 0.86;
      margin-top: 30px;
      .item {
        &:hover {
          background: url('../images/file-bg.png') no-repeat center bottom;
        }
        .icon {
          width: 333px;
          height: 333px;
          margin: -40px -37px 0;
        }
        .name {
          max-width: 200px;
          height: 25px;
          line-height: 25px;
          font-size: 18px;
          font-weight: bold;
          color: #234984;
          margin-top: -69px;
        }
        .btns {
          position: relative;
          z-index: 9;
          .btn {
            margin: 10px 15px;
            border-radius: 6px;
            background: #FFFFFF;
            box-shadow: 0px 0px 4px 0px rgba(64,103,146,0.29);
            font-size: 14px;
            font-weight: 400;
            color: #698ABB;
            text-shadow: 0px 0px 4px rgba(64,103,146,0.29);
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.files-dialog.el-message-box {
  width: 90% !important;
  height: 90% !important;
}
</style>
