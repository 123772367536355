<template>
  <div class="report-pager">
    <div v-if="showPage" class="report-container">
      <div class="title flex jc-between ai-center">
        <div class="name">报告</div>
        <div class="btns flex ai-center">
          <el-button :type="type === 1 ? 'primary' : 'default'" @click="handleTypeClick(1)">填写报告</el-button>
          <el-button :type="type === 0 ? 'primary' : 'default'" @click="handleTypeClick(0)">上传报告</el-button>
        </div>
      </div>
      <div class="report-content">
        <div v-if="type === 1">
          <table>
            <tr>
              <th style="width: 120px">实验名称</th>
              <td :colspan="3">{{ info.name || '--' }}</td>
            </tr>
            <tr>
              <th>学校</th>
              <td>{{ info.school || '--' }}</td>
              <th>专业</th>
              <td>{{ info.major || '--' }}</td>
            </tr>
            <tr>
              <th>姓名</th>
              <td>{{ info.userName || '--' }}</td>
              <th>学号</th>
              <td>{{ info.studentId || '--' }}</td>
            </tr>
            <tr>
              <th>实验目的</th>
              <td :colspan="3">
                <el-input v-if="info.userType == 0" v-model="form.studyObjective" placeholder="请输入实验目的" type="textarea" :rows="4"></el-input>
                <div v-else>{{ form.studyObjective }}</div>
              </td>
            </tr>
            <tr>
              <th>实验内容</th>
              <td :colspan="3">
                <el-input v-if="info.userType == 0" v-model="form.studyContent" placeholder="请输入实验内容" type="textarea" :rows="8"></el-input>
                <div v-else>{{ form.studyContent }}</div>
              </td>
            </tr>
            <tr>
              <th>实验步骤</th>
              <td v-html="info.studyStep" :colspan="3" class="studyStep"></td>
            </tr>
            <tr>
              <th>实验总结</th>
              <td :colspan="3">
                <el-input v-if="info.userType == 0" v-model="form.studySummary" placeholder="请输入实验总结" type="textarea" :rows="12"></el-input>
                <div v-else>{{ form.studySummary }}</div>
              </td>
            </tr>
          </table>
        </div>
        <div v-else class="flex jc-center">
          <el-upload
            :headers="{ Token }"
            class="avatar-uploader"
            action="/studyPc/upload/uploadPicture"
            :disabled="info.userType == 1"
            :limit="1"
            :show-file-list="true"
            :on-success="httpRequestSuccess"
            :before-upload="beforeAvatarUpload">
            <!-- <iframe v-if="form.reportWorld" :src="'https://view.officeapps.live.com/op/view.aspx?src=' + form.reportWorld" frameborder="0" class="avatar"></iframe> -->
            <img src="../images/upload-icon.png" class="avatar-uploader-icon">
          </el-upload>
        </div>
        <div v-if="info.userType == 1" class="teacherForm">
          <el-form
            :model="form"
            :rules="rules"
            ref="formRef"
            label-position="top"
          >
            <el-form-item label="评论" prop="teacherComment">
              <el-input v-model="form.teacherComment" placeholder="请输入评论" type="textarea" :rows="4"></el-input>
            </el-form-item>
            <el-form-item label="打分" prop="teacherScore">
              <el-input v-model="form.teacherScore" placeholder="请输入分数"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="btns flex jc-center">
          <el-button type="primary" @click="handleSubmit()">提交</el-button>
          <el-button @click="handleCancel(0)">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { MyInfo, queryStudyDetail, fillInReport, studyTeacherScore } from "@/api/ApiConfig";
export default {
  components: {},
  props: ['pager', 'params'],
  data() {
    return {
      showPage: false,
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      Token: localStorage.getItem('pcToken') || '',
      type: 1,
      info: {
        userType: '0',
        name: '',
        school: '',
        major: '',
        studentId: '',
        userName: '',
        studyStep: ''
      },
      form: {
        studyObjective: '',
        studyContent: '',
        studySummary: '',
        reportWorld: '',
        teacherComment: '',
        teacherScore: ''
      },
      rules: {

      },
      is_teacher: true
    };
  },
  async created() {
    this.showPage = false
    if(this.params.data) {
      // 老师
      this.info.school = this.params.data.school
      this.info.major = this.params.data.major
      this.info.studentId = this.params.data.studentId
      this.info.userName = this.params.data.userName
      this.form.studyObjective = this.params.data.studyObjective
      this.form.studyContent = this.params.data.studyContent
      this.form.studySummary = this.params.data.studySummary
      this.form.reportWorld = this.params.data.reportWorld
      this.form.teacherComment = this.params.data.teacherComment
      this.form.teacherScore = this.params.data.teacherScore
      await this.getInfo(0)
    } else {
      // 学生
      await this.getInfo(1)
    }
    this.showPage = true
    this.queryStudyDetail()
  },
  methods: {
    async getInfo(type) {
      const res = await MyInfo()
      if (res.code == 200) {
        this.info.userType = res.data.userType
        if(type == 1) {
          // 学生
          this.info.school = res.data.school
          this.info.major = res.data.major
          this.info.studentId = res.data.studentId
          this.info.userName = res.data.userName
        }
      }
    },
    async queryStudyDetail() {
      const res = await queryStudyDetail({
        id: this.laboratory_id
      })
      if (res.code === 200) {
        this.info.name = res.data.name
        this.info.studyStep = res.data.studyStep
      }
    },
    handleTypeClick(type) {
      this.type = type
    },
    async httpRequestSuccess(res) {
      this.form.reportWorld = `https://ai.cyzntech.com/File/${res.data}`;
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    async handleSubmit() {
      if(this.info.userType == 0) {
        const res = await fillInReport({
          studyId: this.laboratory_id,
          reportText: '',
          reportWorld: this.form.reportWorld,
          studyContent: this.form.studyContent,
          studyObjective: this.form.studyObjective,
          studySummary: this.form.studySummary
        })
        if(res.code == 200) {
          this.$message.success('实验报告保存成功')
        } else {
          this.$message.success('实验报告保存失败')
        }
      } else if(this.info.userType == 1) {
        const res = await studyTeacherScore({
          id: this.params.id,
          teacherComment: this.form.teacherComment,
          teacherScore: this.form.teacherScore
        })
        if(res.code == 200) {
          this.$message.success('实验报告打分成功')
        } else {
          this.$message.success('实验报告打分失败')
        }
      }
    },
    handleCancel() {
      this.$emit('change', {
        pager: 'pager1Home',
        params: {}
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.report-pager {
  background-color: #F4F5F8;
  overflow: hidden;
  .report-container {
    width: 1200px;
    margin: 50px auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
    border-radius: 20px;
    opacity: 0.86;
    padding: 20px 30px;
    .title {
      .name {
        font-weight: bold;
        font-size: 20px;
        color: #575757;
        line-height: 37px;
      }
    }
    .report-content {
      margin: 20px 0;
    }
    table {
      width: 100%;
      margin: 20px 0;
      border-radius: 20px;
      border: 1px solid #979797;
      border-collapse: collapse;
      line-height: 48px;
      th, td {
        border: 1px solid #979797;
        text-align: center;
        font-size: 14px;
        color: #575757;
        /deep/ .el-textarea__inner {
          border: none;
        }
      }
      .studyStep {
        text-align: left !important;
        padding: 20px;
      }
    }
    /deep/.avatar-uploader {
      .el-upload {
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .avatar-uploader-icon {
        width: 268px;
        height: 190px;
      }
      .avatar {
        width: 268px;
        height: auto;
        display: block;
      }
    }
    .teacherForm {
      /deep/ .el-form--label-top .el-form-item__label {
        font-weight: bold;
        font-size: 20px;
        color: #575757;
        line-height: 36px;
      }
      /deep/ .el-input__inner, /deep/ .el-textarea__inner {
        border-radius: 18px;
        border: 1px dashed #979797;
        font-size: 16px;
        color: #999999;
        line-height: 36px;
        padding: 9px 16px;
        box-sizing: content-box;
      }
    }
  }
}
</style>