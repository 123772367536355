<template>
  <div class="laboratory2">
    <LabHeader ref="LabHeader" :pager="pager" :params="params" @change="changePager" />
    <Pager2Home v-if="pager == 'pager2Home'" :pager="pager" :params="params" @change="changePager" />
    <Pager2Team v-else-if="pager == 'pager2Team'" :pager="pager" :params="params" @change="changePager" />
    <Pager2Resource v-else-if="pager == 'pager2Resource'" :pager="pager" :params="params" @change="changePager" />
    <Pager2Questions v-else-if="pager == 'pager2Questions'" :pager="pager" :params="params" @change="changePager" />
    <Pager2Setting v-else-if="pager == 'pager2Setting'" :pager="pager" :params="params" @change="changePager" />
    <Pager2Report v-else-if="pager == 'pager2Report'" :pager="pager" :params="params" @change="changePager" />
    <Pager2Third v-else-if="pager == 'pager2Third'" :pager="pager" :params="params" @change="changePager" />
    <LabFooter />
  </div>
</template>
<script>
import LabHeader from "./components/header";
import LabFooter from "./components/footer";
import Pager2Home from "./home";
import Pager2Team from "./team";
import Pager2Resource from "./resource";
import Pager2Questions from "./questions";
import Pager2Setting from "./setting";
import Pager2Report from "./report";
import Pager2Third from "./third";

export default {
  components: {
    LabHeader, 
    LabFooter,
    Pager2Home,
    Pager2Team,
    Pager2Resource, 
    Pager2Questions, 
    Pager2Setting,
    Pager2Report,
    Pager2Third,
  },
  data() {
    return {
      pager: localStorage.getItem('pager') || 'pager2Home',
      params: JSON.parse(localStorage.getItem('pager_params')) || {}
    };
  },
  computed: {},
  mounted() {},
  methods: {
    changePager({pager, params}) {
      console.log(321, pager, params)
      localStorage.setItem('pager', pager)
      localStorage.setItem('pager_params', JSON.stringify(params))
      this.pager = pager
      this.params = params
      this.$refs.LabHeader.changeCurrent({
        pager
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.laboratory2 {
  background-color: #F4F5F8;
  // background: url('../images/lab-bg2.png') no-repeat left 42px top 1020px / 269px 267px, url('../images/lab-bg3.png') no-repeat right 42px top 1748px / 329px 327px;
}
</style>
